<template>
  <div>
    <v-snackbar
      v-model="updateVersionSnackbar"
      color="success"
      transition="slide-y-reverse-transition"
      :timeout="-1"
      app
      bottom
      max-width="1600px"
    >
      <v-layout align-center fill-height>
        <template>
          <v-icon left> mdi-alert</v-icon>
        </template>
        <p class="mb-0 mr-4">
          {{ $t("Version has been successfully upgraded!") }}
        </p>
        <v-btn text @click="$store.dispatch('setUpdateVersionSnackbar', false)">
          {{ $t("Ok") }}
        </v-btn>
      </v-layout>
    </v-snackbar>
    <v-snackbar
      v-model="updateSnackbar"
      color="warning"
      transition="slide-y-reverse-transition"
      :timeout="-1"
      app
      bottom
      max-width="1600px"
    >
      <v-layout align-center fill-height>
        <template>
          <v-icon left> mdi-alert</v-icon>
        </template>
        <p class="mb-0 mr-4">
          {{
            $t(
              "There is an update available click on the update button to update."
            )
          }}
        </p>
        <v-btn text @click="$store.dispatch('setUpdateSnackbar', false)">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn text @click="$store.dispatch('updateVersion')">
          {{ $t("Update") }}
        </v-btn>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      updateSnackbar: "updateSnackbar",
      updateVersionSnackbar: "isSuccessfullyUpdated",
    }),
  },
};
</script>

<style>
</style>